import * as React from 'react';
import { IconNames, Tab, Tabs, Button, Section } from '..';
import styled from 'styled-components';
import useMeasure from 'react-use/lib/useMeasure';

const StyledDiv = styled.div`
    background: inherit;

    & section.tabs { padding-top: 0.5rem; }
    & .subTabs { padding: 0; background: inherit; }
    @media(max-height: 768px) {
        section.tabs { padding-bottom: 0.5rem; }
    }
    @media(max-height: 650px) {
        && .sticky { position: inherit; }
    }

    .error-details { font-style: italic; padding: 1em; }
`;
const TabNav = styled.div`
    & .message { margin-top: 1em; }
    & .buttons { display: flex; margin-top: 1em; }
    & .fill { flex: 1; }
`;

type TabName<S> = string | ((s: S, indexOverride?: number) => string);
export interface ManagedTab<S, M = undefined, P = any> {
    id?: string;
    name: TabName<S>;
    component?: React.FC<P>;
    componentProps?: P,
    icon?: IconNames;
    tabs?: ManagedTab<S, M>[],
    tabsHeader?: (s: S) => React.ReactElement,
    hidden?: (s: S) => boolean;
    hideNav?: (s: S) => boolean;
    validator?: (s: S, m: M, i?: number, j?: number) => TabError<S>[];
    submit?: (s: S, m: M, allErrors: TabError<S>[]) => boolean | undefined;
    submitText?: string;
	indexOverride?: number;
	index2Override?: number;
    padding?: string;
	preTabContent?: any;
	fluid?: boolean;
}

type Level = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 'max';
type NextLevel<Level> =
    Level extends 0 ? 1
    : Level extends 1 ? 2
    : Level extends 2 ? 3
    : Level extends 3 ? 4
    : Level extends 4 ? 5
    : Level extends 5 ? 6
    : 'max';
type IndexLevel = 'i' | 'j' | 'k';
type NextIndexLevel<IndexLevel> = IndexLevel extends 'i' ? 'j'
    : IndexLevel extends 'j' ? 'k' : 'i';
type Primitive = string | number | bigint | boolean | undefined | symbol;
export type PropertyStringPath<T, Prefix = '', L extends Level = 0, I extends IndexLevel = 'i'> = {
    [K in keyof T]:
        L extends 'max' ? never :
        T[K] extends Primitive ? `${string & Prefix}${string & K}` :
        T[K] extends Array<any> ? PropertyStringPath<T[K][0], `${string & Prefix}${string & K}[${string & I}].`, NextLevel<L>, NextIndexLevel<I>> :
        `${string & Prefix}${(string | number) & K}` | PropertyStringPath<T[K], `${string & Prefix}${string & K}.`, NextLevel<L>, I>;
}[keyof T];

export type TabError<S> = {
    msg?: string;
    field?: PropertyStringPath<S, 's.'>;
    hide?: boolean;
    tab?: string;
    i?: number | string;
    j?: number | string;
    k?: number | string;
} | null;

interface Props<S, M> {
    tabs: ManagedTab<S, M>[];
    state: S;
    adminState?: M;
    align?: 'left' | 'centered' | 'right';
    onSubmit?: () => void;
    selectedTabId?: string | undefined;
    setSelectedTabId?: React.Dispatch<React.SetStateAction<string | undefined>>;
    sticky?: boolean | number;
    children?: React.ReactNode;
    navigation?: boolean;
    hashUpdate?: boolean;
}

export function allTabErrors<S, M>(tabs: ManagedTab<S, M>[], state: S, adminState?: M): TabError<S>[] {
    return tabs.filter(t => !t.hidden || !t.hidden(state)).reduce((all, tab) => {
        const tabErrors = tab.validator ? tab.validator(state, adminState!, tab.indexOverride, tab.index2Override).filter(e => e) : [];
        const subTabErrors = tab.tabs ? allTabErrors(tab.tabs, state, adminState) : [];
        return [
            ...all,
            ...tabErrors.map(e => ({ ...e, tab: tab.id })),
            ...subTabErrors,
        ].filter(e => e != null);
    }, [] as TabError<S>[]);
}

function scrollToTop() {
    // Scroll back to the top
    setTimeout(() => {
        const el = document?.getElementById("managed-tabs");
        const offsetTop = el?.offsetTop || 0;
        window?.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }, 0);
}

export function ManagedTabs<S, M = undefined>(props: Props<S, M>): React.ReactElement {
    const { tabs, state, adminState, align, onSubmit, selectedTabId, setSelectedTabId, sticky, children, navigation = true, hashUpdate = true } = props;
    const [submitting, setSubmitting] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);
    const [refMain, { height }] = useMeasure();

    // Internal tab state
    let [tabId, setTabId] = React.useState<string | undefined>(undefined);

    // Let parent manage selected tab
    if(selectedTabId !== undefined && setSelectedTabId !== undefined) {
        tabId = selectedTabId;
        setTabId = setSelectedTabId;
    }

    function changeTab(id: string | undefined) {
        setTabId(id);
        if(hashUpdate) {
            const baseUrl = window.location.href.split('#')[0];
            window.location.replace(`${baseUrl}#${id}`);
        }
        scrollToTop();
    }
    function setFromHash() {
        const hash = window.location.hash?.replace('#', '');
        if(hash && hash !== tabId) {
            setTabId(hash);
        }
    }

    React.useEffect(() => {
        if(!hashUpdate)
            return;

        // Set initial tab from URL hash
        setFromHash();

        // Watch for hash changes
        if(("onhashchange" in window)) {
            window.onhashchange = function () {
                setFromHash();
            }
        }

        return () => {
            window.onhashchange = null;
        };
    }, []);

    // Find the selected tab
    const visibleTabs = tabs.filter(t => !t.hidden || !t.hidden(state));
    const mainTab = visibleTabs.find(t => {
        return t.id && t.id === tabId || t.tabs?.find(s => s.id === tabId);
    }) || visibleTabs[0];
    const mainTabIndex = visibleTabs.findIndex(t => t.id === mainTab?.id);

    // Find the selected subTab
    const visibleSubTabs = mainTab.tabs?.filter(t => !t.hidden || !t.hidden(state)) || [];
    const hasSubTabs = visibleSubTabs.length > 0;
    const subTab = visibleSubTabs.find(t => t.id && t.id === tabId) || (hasSubTabs ? visibleSubTabs[0] : undefined);
    const subTabIndex = visibleSubTabs.findIndex(t => t.id === subTab?.id);

    // Get selected tab errors
    const errors: TabError<S>[] = subTab || mainTab ? allTabErrors([subTab || mainTab], state, adminState) : [];
    const visibleErrors = [...new Set([
        errors.filter(e => !e?.msg).length ? { msg: 'Some required fields are missing.' } : null,
        ...errors.filter(e => !e?.hide && e?.msg),
    ].filter(e => e).map(e => e?.msg))];

    // Get all visible tab errors
    const allErrors = allTabErrors(visibleTabs, state, adminState);

    const isLast = mainTabIndex + 1 === visibleTabs.length;
    const submitStatus = mainTab?.submit ? mainTab.submit(state, adminState!, allErrors) : subTab?.submit ? subTab?.submit(state, adminState!, allErrors) : undefined;
    const submitText = mainTab?.submitText || subTab?.submitText || 'Submit';

    function prev() {
        // Change to previous sub tab
        if(subTabIndex > 0) {
            changeTab(visibleSubTabs[subTabIndex - 1]?.id);
            return;
        }

        // Change to previous main tab
        changeTab(visibleTabs[mainTabIndex - 1]?.id);
    }
    function next() {
        if(!subTab || subTabIndex + 1 === visibleSubTabs.length)
            changeTab(visibleTabs[mainTabIndex + 1]?.id);
        else
            changeTab(visibleSubTabs[subTabIndex + 1]?.id);
    }
    async function submit() {
        if(!onSubmit)
            return;

        setSubmitting(true);
        await onSubmit();
        setSubmitting(false);
    }

    const hideNav = subTab?.hideNav?.(state) || mainTab?.hideNav?.(state);
    return <StyledDiv id="managed-tabs">
        <Tabs align={align} sticky={sticky}
            selectedTab={mainTabIndex === -1 ? 0 : mainTabIndex}
            onTabChange={i => changeTab(visibleTabs[i]?.id)}
            preTabContent={children}
            tabsRef={refMain as React.Ref<HTMLDivElement>}
            mb={mainTab?.padding}
        >
            {visibleTabs.map(t => {
                const tabErrors = allTabErrors([t], state, adminState);
                const name = typeof t.name === 'function'
                    ? t.name(state, t.indexOverride) : t.name;
                return <Tab key={name} name={name} fluid={t.fluid} render={t.component ? () => React.createElement(t.component!, {
					state, adminState, tabErrors, allErrors, indexOverride: t.indexOverride, index2Override: t.index2Override, ...t.componentProps
                }) : undefined} errors={tabErrors} icon={t.icon} />;
            })}
        </Tabs>

        {visibleSubTabs.length ? <div className="subTabs">
            {mainTab.tabsHeader ? mainTab.tabsHeader(state) : null}
            <Tabs align={align} selectedTab={subTabIndex} onTabChange={t => {
                changeTab(visibleSubTabs[t]?.id);
            }} mt="0" sticky={height} preTabContent={mainTab?.preTabContent}>
                {visibleSubTabs.map(t => {
                    const tabErrors = t.validator ? t.validator(state, adminState!, t.indexOverride, t.index2Override).filter(e => e) : [];
                    const name = typeof t.name === 'function'
                        ? t.name(state, t.indexOverride) : t.name;
                    return <Tab key={name} name={name} render={t.component ? () => React.createElement(t.component!, {
                        state, adminState, tabErrors, allErrors, indexOverride: t.indexOverride, index2Override: t.index2Override, ...t.componentProps
                    }) : undefined} errors={tabErrors} icon={t.icon} />;
                })}
            </Tabs>
        </div> : null}

        <Section style={{ paddingBottom: '1.5em' }}>
            <TabNav>
                {/* Render any errors for the selected tab */}
                {visibleErrors && visibleErrors.length > 0 ? <div className="message is-warning" onDoubleClick={() => setShowErrors(!showErrors)}>
                    <div className="message-body">
                        {visibleErrors.map((e, i) => <div key={i}>
                            {e}
                        </div>)}

                        {showErrors ? <div className="error-details">{errors.map((e, i) => <div key={i}>
							{(e?.field ?? e?.msg)?.replace('[i]', `[${e.i}]`).replace('[j]', `[${e.j}]`).replace('[k]', `[${e.k}]`)}
                        </div>)}</div> : null}
                    </div>
                </div> : null}

                {hideNav || !navigation ? null : <div className="buttons">
                    {mainTabIndex > 0 ? <button type="submit" className="button is-dark" onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        prev();
                    }}>Previous</button> : null}

                    <div className="fill" />

                    {submitStatus != undefined ? <Button type="submit" color="secondary" loading={submitting}
                        disabled={!onSubmit || submitStatus === false || submitting} onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            submit();
                        }}>{submitText}</Button> : null}

                    {isLast ? null : <button type="submit" className="button is-dark" onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        next();
                    }}>Next</button>}
                </div>}
            </TabNav>
        </Section>
    </StyledDiv>;
};
